@import "abstracts/_abstracts";
.AppPopup {
	$block: &;
	@extend %modalWrapper;

	// možné pozice popupu
	&--1, &--4, &--7, &--14 { justify-content: flex-start; }
	&--3, &--6, &--9, &--16 { justify-content: flex-end; }
	&--1, &--2, &--3 { align-items: flex-start; }
	&--7, &--8, &--9 { align-items: flex-end; }
	&--14, &--16 { align-items: stretch; }

	&-inner {
		@extend %modalContent;
		margin: 0;

		#{$block}--5 & {
			margin: auto;
		}

		#{$block}.is-active & {
			@extend %modalContent-active;
		}

		&.is-simple {
			padding: 0;
			background: transparent;
		}
	}

	&-close {
		@extend %modalContentCross;

		&.is-small {
			width: 2em;
			height: 2em;
		}
	}

	&-title {
		font-size: var(--textMd);
		margin: -1em -1em 1em;
		padding: .7em 1em;
		padding-right: 3em;
		border-bottom: 1px solid var(--colorOutlines);
	}
}